<template>
    <div id="data-list-list-view" class="data-list-container">
  
      <div id="ecommerce-wishlist-demo">
        <div class="items-grid-view vx-row match-height" appear>
          <template v-if="categories.length">
            <div class="vx-col lg:w-1/1 md:w-1/1 sm:w-1/1 w-full mb-5">
              <vs-button @click="$router.push({name:'brands-create'})" class="
                  btn-add-new
                  p-3
                  mb-4
                  mr-4
                  rounded-lg
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                ">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2">إضافة جديد</span>
              </vs-button>
            </div>
  
            <div class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full" v-for="item in categories" :key="item.id">
              <item-grid-view :item="item" image_key="icon">
                <!-- SLOT: ACTION BUTTONS -->
                <template slot="action-buttons">
                  <div class="flex flex-wrap">
                    <div class="
                        item-view-secondary-action-btn
                        bg-primary
                        p-3
                        flex flex-grow
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                      " 
                      @click="editData(item)">
                      <span class="text-sm font-semibold ml-2"> تعديل الأعلانات </span>
                    </div>
                  </div>
                </template>
              </item-grid-view>
  
            </div>
          </template>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import moduleCategory from "@/store/category/moduleCategory.js";
  const ItemGridView = () => import("../brands/components/ItemGridView.vue");
  
  export default {
    components: {
      ItemGridView,
    },
    data() {
      return {

      };
    },
    computed: {
      categories() {
        return this.$store.state.category.categories;
      },
    },
    methods: {
      editData(data) {
        this.$router.push({name: 'category-edit',params: { id: data.id },})
      },
      getData() {
        this.$vs.loading();
        this.$store
          .dispatch("category/fetchCategories")
          .then(() => {
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      },
    },
    created() {
      if (!moduleCategory.isRegistered) {
        this.$store.registerModule("category", moduleCategory);
        moduleCategory.isRegistered = true;
      }
      this.getData();
    },
    mounted() {
      this.isMounted = true;
    },
  };
  </script>
  